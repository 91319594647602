import './App.css';
import { useState } from 'react';
import { ethers } from 'ethers'
import Greeter from './artifacts/contracts/Greeter.sol/Greeter.json'
import Doof from './artifacts/contracts/DoofToken.sol/DoofToken.json'

require('dotenv').config();
const { 
  REACT_APP_CONTRACT_ADDRESS, 
  REACT_APP_GREETER_ADDRESS, 
  REACT_APP_API_URL,
  REACT_APP_CONTENT_HASH,
  REACT_APP_METADATA_URL,
  REACT_APP_PRIVATE_KEY,
  REACT_APP_PUBLIC_KEY } = process.env;

const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(REACT_APP_API_URL);

const nftContract = new web3.eth.Contract(Doof.abi, REACT_APP_CONTRACT_ADDRESS)

function App() {


    const [greeting, setGreetingValue] = useState()
    const [userAccount, setUserAccount] = useState()
    const [amount, setAmount] = useState()

    async function requestAccount() {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    }

    async function fetchGreeting() {
      if (typeof window.ethereum !== 'undefined') {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        console.log({ provider })
        const contract = new ethers.Contract(REACT_APP_GREETER_ADDRESS, Greeter.abi, provider)
        try {
          const data = await contract.greet()
          console.log('data: ', data)
        } catch (err) {
          console.log("Error: ", err)
        }
      }    
    }

    async function setGreeting() {
      if (!greeting) return
      if (typeof window.ethereum !== 'undefined') {
        await requestAccount()
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        console.log({ provider })
        const signer = provider.getSigner()
        const contract = new ethers.Contract(REACT_APP_GREETER_ADDRESS, Greeter.abi, signer)
        const transaction = await contract.setGreeting(greeting)
        await transaction.wait()
        fetchGreeting()
      }
    }

    async function getBalance() {
      if (typeof window.ethereum !== 'undefined') {
        const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' })
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(REACT_APP_CONTRACT_ADDRESS, Doof.abi, provider)
        const balance = await contract.balanceOf(account);
        console.log("Balance: ", balance.toString());
      }
    }


    async function mintNFT() {
      //Get the latest nonce
    const nonce = await web3.eth.getTransactionCount(REACT_APP_PUBLIC_KEY, 'latest')
    const gas = 500000
    //Transaction
    const tx = {
      'from': REACT_APP_PUBLIC_KEY,
      'to': REACT_APP_CONTRACT_ADDRESS,
      'nonce': nonce,
      'gas': gas,
      'data': nftContract.methods.safeMint(REACT_APP_PUBLIC_KEY, REACT_APP_CONTENT_HASH, REACT_APP_METADATA_URL).encodeABI()
    }

    const signPromise = web3.eth.accounts.signTransaction(tx, REACT_APP_PRIVATE_KEY)
    signPromise
        .then((signedTx) => {
            web3.eth.sendSignedTransaction(
                signedTx.rawTransaction,
                function (err, hash) {
                if (!err) {
                    console.log(
                    "The hash of your transaction is: ",
                    hash,
                    "\nCheck Alchemy's Mempool to view the status of your transaction!"
                    )
                } else {
                    console.log(
                    "Something went wrong when submitting your transaction:",
                    err
                    )
                }
                }
            )
        })
        .catch((err) => {
            console.log(" Promise failed:", err)
        })
    }

    return (
      <div className="App">
        <header className="App-header">
          <button onClick={fetchGreeting}>Fetch Greeting</button>
          <button onClick={setGreeting}>Set Greeting</button>
          <input onChange={e => setGreetingValue(e.target.value)} placeholder="Set greeting" />

          <br />
          <button onClick={mintNFT}>Mint NFT</button>
        </header>
      </div>
    );
}

export default App;
